/*!
 *
 * Copyright 2016 Google Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *p
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the Liacense is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.page-content {
    height: 92vh;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
}

.Sidebar-menu {
    background-color: #112a45;
    /*background-color:#1E232A;*/
    flex: 0 0 22%;
    height: 100%;
    max-width: 280px;
    overflow: auto;
    z-index: 3000;
}

.row.admin {
    background-color: #112a46;
    padding-top: 5px !important;
}
.select-style select {
    color: #888271 !important;
}
.form-sheet {
    border-radius: 0px !important;
}

.Main-view {
    flex: 2;
    display: flex;
    overflow: auto;
}
.Main-view-col {
    padding: 20px 30px 0 30px;
}
.Main-view .fab {
    display: block;
    position: absolute;
    top: 30px;
    left: 23%;
    z-index: 10000;
}

.mode-context-trigger {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
    background-color: #1e232a;
    box-shadow: -1px 0 4px 0 #1e232a;
    padding: 20px 16px;
}

.init .mode-context-header {
    padding-top: 1px;
}

//Removes padding on header only
header.mode-context-header.site-logo.has-logo {
    padding: 0px;
}

ol.breadcrumbs li a {
    color: #959595 !important;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 13px;
}
.breadcrumbs li:after {
    content: "\00bb" !important;
    color: #959595 !important;
}

.mode-context-header {
    padding: 0 24px;
    position: relative;
}

.mode-context-header .breadcrumbs {
    display: block;
    margin: 16px 0;
}

.mode-context-header .mode-context-title {
    white-space: normal;
    overflow: visible;
    padding-right: 0;
}
.init .mode-context-header .mode-context-title {
    width: 100%;
}

.mode-context-title {
    color: #fff;
    font-size: 20px;
    font-size: 1.428571429rem;
    line-height: 1.3em;
}

.mode-context-title {
    font-size: 1.857142857rem;
}
.mode-context-header .breadcrumbs li a {
    color: white;
}
.Main-view .toolbox {
    top: 90px;
    right: 55px;
    display: block;
    position: fixed;
    z-index: 1000;
    width: 206px;
    box-shadow: none;
    background-color: transparent;
}

.Main-view .main-col {
    margin-right: 222px;
    width: 100%;
}

.Main-view .main-col_report {
    flex: 1;
    width: 100%;
    padding: 8px;
}

.init .mode-context-body {
    display: block;
    color: white;
}
.init .mode-context-body p {
    padding: 0 16px 0 24px;
}

main.Main-view[role="main"] {
    padding: 0;
}

main.Main-view[role="main"] .map.full-size {
    z-index: inherit;
    top: 64px;
}

main.Main-view[role="main"] .map-view {
    z-index: inherit;
}

.skip-header {
    padding: 10px;
    text-align: center;
}

h3.modal-title {
    text-align: center;
    text-transform: uppercase;
}

.form-field.button-group-skip {
    text-align: center;
}

button.button-black {
    background-color: black;
    color: white;
}

.Main-view .post-detail-row {
    display: flex;
    width: 100%;
    margin: 3%;
    flex-direction: column;
}

.post-detail-row .postcard-body-content {
    display: flex;
    width: 100%;
    flex-direction: row;
}

.postcard-body-content .row {
    flex: 1;
    padding: 0 5px;
}

.row .postcard-field {
    /*margin: 0;*/
    /*
    margin-left: 15px;
    margin-right: 15px
    */
}

.row .form-label.label-location {
    padding-top: 0 !important;
}

.form-label.label-post-details {
    color: black;
    font-weight: bold;
    font-size: 16px;
}

.form-label.label-post-related {
    color: #e69327;
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
}

.tag-list {
    width: 100%;
    max-width: 500px;
    margin: auto;
    padding: 1rem;
    padding-left: 0px;
    padding-bottom: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid rgba(white, 0.2);
    list-style-type: none;

    &:before {
        content: "";
        flex-basis: 100%;
        height: 0;
        // uncomment to see the hack
        // height: 10px;
        // background-color: green;

        order: 2;
    }
}

.tag {
    display: block;
    padding: 7px;
    /*border-radius: 10px;*/
    margin-left: 0px;
    list-style-type: none;
    font-size: 1rem;
    font-weight: bold;
    line-height: 0.7rem;
    color: rgba(255, 255, 255, 1);
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #444;
    transform-origin: 0% 50%;
    order: 1;
    background-color: #1e2329;
}
//Custom CSS Added By Brian

//The x-axis of Title of incidences, and summary and details, becomes the same
.postcard .listing-item-select + .postcard-body {
    /*  padding-right: 24px !important;
    padding-left: 24px !important; */
}
.postcard-header {
    /* padding-left:16px !important;*/
}

.form-sheet .toolbox .tool {
    /* margin: 16px 4px !important;*/
    /*margin-right:4px !important;*/
}

.postcard-field-related {
    margin-top: 10px;
}

.row {
    border: 1px solid rgba(0, 0, 0, 0.05);
    margin-right: 5px;
    padding-left: 7px !important;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}
//Added to reduce the size so that it fits on the top of the site
.deployment-logo {
    width: 48px !important;
    height: 48px !important;
}

h2.mode-context-title.my_incidents.active,
h2.mode-context-title.assigned_incidents.active {
    /* background-color: gray; */
    padding-left: 0px;
    margin-left: 10px;
    color: #ffc338;
}
.Main-view .main-col {
    /*margin-right:0px !important;*/
}

h2.mode-context-title,
h2.mode-context-title {
    /* background-color: gray; */
    border-bottom: 1px solid rgba(128, 128, 128, 0.1);
    padding-top: 2px;
    padding-bottom: 2px;
}

h2.mode-context-title.personal.active {
    padding-left: 0px;
    color: #ffc338;
}

.form-field.checkbox label {
    font-size: 14px !important;
    /*font: 14px "Helvetica Neue",Arial,Helvetica,sans-serif !important;*/
}

h2.mode-context-title.my_incidents.active::before,
h2.mode-context-title.assigned_incidents.active::before {
    content: "\00bb" !important;
    color: #959595 !important;
    vertical-align: top;
    font-size: 13px;
    margin-right: 5px;
}

h2.mode-context-title.my_incidents::before,
h2.mode-context-title.assigned_incidents::before {
    content: "\00bb" !important;
    color: #959595 !important;
    vertical-align: top;
    font-size: 13px;
    margin-right: 5px;
}

.site-title {
    float: left;
    color: #243953;
    background-color: #ffc335;
    position: absolute;
    left: 77px;
    top: 6px;
    padding: 5px;
    font-size: 21px;
    border-radius: 2px;
}

.site-description {
    float: left;
    margin-left: 1px;
    vertical-align: bottom;
    margin-top: 5px;
    height: 20px;
    margin-top: 20px;
    font-size: 10px;
}
a.site-title:hover {
    color: white !important;
}
.site-logo {
    float: left;
}

img.postcard-image {
    left: 0px !important;
}

// Makes the incidence status, appear without hover, in timeline view
span.bug.status {
    /*display: block !important;*/
    box-shadow: none !important;
    border-radius: 4px;
    /*  top:-6px !important;*/
    /* top:28px !important;*/
    /*left:200px !important;*/
    padding: 8px 8px;
    float: right;
    line-height: 0.5 !important;
    background-color: #46373d;
}

@media screen and (max-width: 1200px) {
    .site-description {
        display: none !important;
    }
}

//End Custom CSS Added By Brian

.tag-list .tag {
    /*margin: 2px;*/
    margin-right: 2px;
    margin-top: 0px;
    margin-bottom: 2px;
}

.tabs-menu ul {
    overflow-x: overlay !important;
}

.tabs-menu ul li {
    float: left !important;
    background-color: #ffb401;
}

.form-sheet .form-field {
    max-width: none !important;
}

button.button-flat {
    margin: 5px !important;
}

.post-detail-row .postcard .postcard-title {
    font-weight: bold;
    margin-bottom: 0;
}

.post-detail-row .postcard[role="article"] .postcard-title {
    margin-bottom: 10px;
}

.postcard-field .postcard-related .postcard-title {
    font-weight: bold;
    font-size: 16px !important;
    margin-top: 10px;
}

.background-grey {
    background-color: #e8e9ea;
}

.postcard-field-related {
    padding: 8px;
    border: 1px solid rgb(200, 200, 200);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
}

.toolbox .tool [data-toggle] {
    width: 100%;
}

@media screen and (min-width: 64em) {
    .custom-fieldset.init .dropdown-menu {
        min-width: 200px !important;
    }
    [class*="layout-"] .message.active {
        top: 60px !important;
    }
}

select {
    width: 100%;
}

.postcard .postcard-field img.postcard-image {
    position: relative !important;
    max-width: 100% !important;
    height: auto;
    max-height: 402px;
    object-fit: cover;
    display: block;
}

.form-location {
    width: 100%;
    display: flex;
    flex-direction: row;
    .form-field {
        width: 30%;
    }
}

.select-style.assign_to {
    max-width: 300px;
}

.list {
    /*height: 100%;*/
    /*margin:15px;*/
    padding: 0 10px;
    overflow: auto;
    max-height: 350px;
    border: 1px solid rgb(200, 200, 200);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    background: #f7f6f1;
}
.list section.first {
    margin-top: -20px;
}
.list section {
    margin-bottom: 40px;
}
.image {
    float: left;
    width: 45px;
    height: 45px;
    border-radius: 100%;
    margin-right: 5px;
}
.comments h3 {
    font-size: 18px;
    margin-bottom: -10px;
    margin-top: 30px;
}
.comments p {
    font-size: 14px;
}
a {
    text-decoration: none;
    /*color:#666;*/
    transition: 0.3s color;
}
a:hover {
    color: #1bd5a7;
}

.form-sheet .custom-fieldset {
    min-width: 200px;
}

#loading-bar .bar {
    background: #29d;
    position: fixed;
    z-index: 30002 !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 5px;
    border-bottom-right-radius: 2px;
    border-top-right-radius: 2px;
}

a.media-link {
    color: #4078c0;
    font-size: 24px;
    font-style: italic;
    text-decoration: underline;
}
