.mode-context-title.personal,
.mode-context-title.incidents,
.mode-context-title.my_incidents,
.mode-context-title.assigned_incidents{
  font-size: 20px;
}

.mode-context-title.personal.active,
.mode-context-title.incidents.active,
.mode-context-title.my_incidents.active,
.mode-context-title.assigned_incidents.active {
  color: #FFC334;
}

.push-inside {
  margin-left: 30px;
}

.mode-context-body.user_profile {
  margin-top: 30px;
}

.profile-edit {
  float: right;
  position: relative;
  margin-top: 0px;
  a, a:hover{
    color: white;
  }
}

.personal-info {
  ul {
    padding-top: 20px;
    li {
      list-style-type: none;
      padding: 10px;
      font-size: 18px;
      text-transform: uppercase;
      span {
        margin-left: 20px;
        text-transform: none;
      }
    }
  }
}

.profile-container {
    background-color: #fff;
    border-left: 3px solid #FFC334;
    height: 100%
}

form.form {
  justify-content: center;
  padding: 30px;
  .form-group {
    padding: 5px;
    .form__label {
      padding: 3px 0;
    }
  }
}

header.edit-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 0 0;
}


.select-style {
    padding: 0;
    margin: 0;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 450px;
    border-radius: 3px;
    overflow: hidden;
}

.select-style select {
    padding: 5px 8px;
    width: 130%;
    border: none;
    box-shadow: none;
    background-color: transparent;
    background-image: none;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}

.select-style select:focus {
    outline: none;
}

.push-inside .form-field {
  margin-bottom: 6px;
}

.push-inside .form-field.checkbox label {
  color: white;
  font-size: 18px;
}


.form-field.review_checkbox label {
  display: inline-block;
  padding: 0 10px;
}
