
.mode-context-body fieldset legend {
  font-size: 14px;
  color: white;
}

.mode-context-body fieldset {
  margin: 15px;
}

.mode-context-body fieldset .form-field label,
.mode-context-body fieldset .form-field-toggle{
  color: white
}

.mode-context-body fieldset .form-field-toggle:hover,
.mode-context-body fieldset .form-field-toggle:focus {
  color: #1EA362;
}
.mode-context-body fieldset .form-field-toggle svg.iconic {
  fill: white;
}

.mode-context-body fieldset .form-field.date {
  width: 100%;
}
.mode-context-body fieldset .form-field button {
  width: 100%;
}
.mode-context-body fieldset .form-field button.button-link {
  color: #000000;
  background-color: #FFC334
}

.mode-context-body fieldset .form-field.select-within {
  padding: 10px;
  padding-left: 20px;
  padding-right: 0px;
}
.mode-context-body fieldset .form-field.select-within span {
  margin-right: 30px;
}

.mode-context-body fieldset .form-field.select-within .within-km {
  
}

//Custom Added By Brian

select.within-km{
  margin-top:10px !important;
}

//Custom Added By Brian

.mode-context-body .mode-context-body-content {
  padding: 0 20px;
}

.init .mode-context-title svg.iconic {
  width: .769230769em;
  height: .769230769em;
  fill: #C7CCD4;
  margin-right: 4px;
}

.init .mode-context-body {
    padding: 0 16px 10px;
}

.init .metadata {
    margin-bottom: 16px;
}

.init .mode-context-body .bug {
    text-align: left;
    margin: 4px auto;
}


.init .survey-filter {
    margin-right: -24px;
    margin-left: -24px;
}
