@import url(https://fonts.googleapis.com/icon?family=Material+Icons);

.header {
  position: relative;
  height: $baseline * 8;
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
  margin-bottom: $baseline * -3;

  &::before {
    width: 100%;
    position: absolute;
    height: $baseline * 6;
    content: '';
    background: #FFF;
    top: 0;
    left: 0;
  }
}
.form-field-adapt.init textarea, input[type="text"]
{
  border:1px solid #c8c8c8 !important;
  background-color: white !important;
  box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1) !important;
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
}

.form-field-adapt.init textarea, input[type="text"]
{
  border:1px solid #c8c8c8 !important;
  background-color: white !important;
  box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1) !important;
  border-top-left-radius: 2px !important;
  border-top-right-radius: 2px !important;
  border-bottom-left-radius: 2px !important;
  border-bottom-right-radius: 2px !important;
  padding:8px !important;
  padding-left:32px !important;
}

.form-field-adapt.init textarea:focus, input[type="text"]:focus
{
  border:2px solid #c8c8c8 !important;
  background-color: white !important;
  box-shadow: inset 0 1px 1px 0 rgba(0,0,0,0.1) !important;
  outline-color:black !important;
}


.form-field-adapt.init label
{
  position:initial !important;
  color: #888 !important;
  font-weight: 900 !important;
  font-size: .714285714rem !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.header__content {
  display: flex;
  align-items: center;
  width: 100%;
  background: #eee;
  padding: 20px;
  margin: 0;
  height: 8vh;
  min-height: 56px;
  justify-content: space-between;
  /*background-color: $themecolor;*/
  background-color:#243953;
  /*border-bottom: 2px solid rgba(153, 153, 170, 0.7)*/
}

.right_menu {
  padding: $baseline;
  margin-right: 200px;
}

.search_menu {
    margin-left: 70px;
}

.search_menu .searchbar .searchbar-input {
  width: 350px;
}
.search_menu .searchbar .dropdown-menu {
  z-index: 1;
}

.logo__icon {
  display: flex;
  width: 100%;
  padding-left: $baseline * 6;
  height: $baseline * 4;
  overflow: hidden;
  margin-right: $baseline * 2;
  align-items: center;
  text-decoration: none;
}

.menu__nav {
  list-style: none;
  padding: $baseline * 5 0 0 0;
  margin: 0 0 0 120px;
  list-style: none;
  display: none;
  height: 100%;
}

.menu__nav a {
  font-size: 16px;
  color: #FFF;
  width: 100%;
  display: block;
  padding: $baseline;
  text-decoration: none;
}

.menu__nav a:hover,
.menu__nav a:focus {
  color: #1EA362;
  background: #F0F0F0;
}

.menu__nav-item--active a {
  color: #1EA362;
  font-weight: 700;
}

.menu__nav-item-avatar {
  max-height: $baseline * 4;
  border-radius: 50%;
  display: inline !important;
}
.menu__underlay {
  position: fixed;
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.menu__toggle {
  opacity: 0;
  position: absolute;
  top: $baseline * 1.5;
  right: $baseline;
  z-index: 1;
}

.menu__toggle-label {
  width: $baseline * 3;
  height: $baseline * 3;
  display: block;
  position: absolute;
  top: $baseline * 1.5;
  right: $baseline;
  z-index: 2;
}

.menu__toggle:focus ~ .menu__toggle-label {
  box-shadow: 0 0 2px 2px rgba(30, 163, 98, 0.6);
}

@media (max-width: 423px) {
  .menu__toggle:checked ~ .menu__nav {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 90%;
  }
}

@media (min-width: 424px) {
  .header {
    height: $baseline * 11;

    &::before {
      height: $baseline * 8;
    }
  }

  .menu_ {
    display: flex;
    align-items: center;
  }

  .menu__toggle,
  .menu__toggle-label {
    display: none;
  }

  .menu__underlay {
    display: none;
  }

  .menu__nav {
    display: flex;
    align-items: center;
    position: relative;
    height: auto;
    width: auto;
    padding: 0;
  }

  .menu__nav-item {
    display: inline;
    margin-right: $baseline * 2;

    &:last-of-type {
      margin-right: $baseline;
    }
  }

  .menu__nav-item a {
    font-size: 14px;
    padding: 0;
    display: inline;

    &:hover,
    &:focus {
      background: none;
    }
  }

  .logo__icon {
    padding-left: 0;
    color: white;
    height: $baseline * 6;
  }
}

@media (min-width: 600px) {
  .header {
    height: $baseline * 12;
  }

  .header::before {
    height: $baseline * 9;
  }

  .menu__nav-item a {
    font-size: 16px;
  }

  .menu__nav-item:last-of-type {
    margin-right: $baseline * 2;
  }

  .logo__icon {
    padding-left: $baseline * 8;
  }
}

$font: 'Open Sans';
$main: white;

:root {
  font-size: 16px;
}

* {
  box-sizing: border-box;
}

.main-nav {
  display: flex;
  width: 100%;
  height: 4rem;
  align-items: center;
  border-bottom: 1px solid #ddd;
}


.main-nav__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  font-style: normal;
  /*padding: 0 1rem;*/

  .main-nav__branding {
    h2 {
      text-transform: uppercase;
      a {
        color: $main;
      }
    }
  }
}

.main-nav__inner-nav {
  position: relative;
  display: flex;
  align-items: center;

  > a:not(.profile-dropdown-trigger):not(.dropdown-trigger--mobile) {
    padding: 0 .5rem;
    margin: 0 .25rem;
    color: $main;
    font-weight: 500;

    @media(max-width: 1000px) {
      display: none;
    }
  }
}

.profile-dropdown-trigger {
  margin: 0 .25rem;
}

.dropdown-trigger--mobile {
  position: relative;
  margin-right: .75rem;

  @media(min-width: 1000px) {
    display: none;
  }

  i {
    font-size: 2rem;
    color: $main;
  }
}

.mobile-menu-dropdown {
    @media(min-width: 1000px) {
    display: none;
  }
}

.profile-dropdown-trigger {
  position: relative;

  img {
    border: 3px solid #eee;
    padding: .1rem;
    height: 3.25rem;
    width: auto;
    border-radius: 50%;
  }
}

.main-nav__dropdown {
  position: absolute;
  background: #fff;
  top: 3rem;
  right: -.6rem;
  min-width: 15rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2), 0 2px 14px 0 rgba(0, 0, 0, .2);
  border-radius: 3px;
  overflow: hidden;
  transition: 300ms ease;
  z-index: -1;
  opacity: 0;
  transform: translateY(-5px);
  pointer-events: none;
}

.main-nav__dropdown.active {
  opacity: 1;
  z-index: 200;
  pointer-events: auto;
  transform: translateY(0px);
}

.dropdown__header {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 1.5rem;

  &:hover, &:focus {
    background: #f7f7f7;
  }

  img {
    height: 3rem;
    width: auto;
    border-radius: 50%;
  }

  .dropdown__name {
    color: #555;
    margin: .5rem 0 .25rem;
  }

  .dropdown__title {
    font-size: .85rem;
    color: #999;
  }
}

.dropdown__nav {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e5e5e5;

  a {
    color: $themecolor;
    border-bottom: 1px solid #e5e5e5;
    padding: .5rem 1rem;
    // center vertically
    display: flex;

    span:not(.material-icons) {
      margin-left: .35rem;
      display: flex;
      align-items: center;
    }

    &:hover, &:focus {
      background: #f7f7f7;
    }
  }
}

// globals

a {
  &:hover, &:focus {
    text-decoration: none;
  }
}
