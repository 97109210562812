h4.title.text-Centre {
    text-align: center;
}

.report-parent {
    width: 100%;
    display: flex;
    float: left;
}

img.download-as-png {
    height: 34px;
    width: 34px;
    border-radius: 50%;
}

button.download-as-png {
    float: right;
}

.adminreport {
    border: 1px solid rgba(132, 136, 136, 0.59);
    padding: 10px;
    margin: 5px;
    flex: 1;
    background-color: #fff;
}

.Main-view .main-col_report div.aggregate-filters {
    display: flex;
    flex-direction: row;
    align-content: space-between;
}

.Main-view .main-col_report div.aggregate-filters .form-field {
    flex: 1;
    margin: 6px;
}

.dateReportInput {
    padding: 2px !important;
}

.form-field.button-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
}
