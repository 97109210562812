/*!
 *
 * Copyright 2016 Google Inc. All rights reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

.header {
  background-size: 100% 100%;
}

.menu__underlay {
  background: #000;
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 423px) {
  .logo__icon::after {
    content: 'MCT';
  }
}

@media (min-width: 424px) {
  .logo__icon {
    background-size: ($baseline * 6) ($baseline * 6);
  }
}

@media (min-width: 672px) {
  .logo__icon::after {
    content: 'MCT';
  }
}
